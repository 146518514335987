
import { Component, Prop } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import venueHeaders from './headers';
import VTable from '@/components/shared/table/VTable.vue';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VColumnSelect from '@/components/shared/table/VColumnSelect.vue';
import { Permission } from '@/enums/Permission';
import VenueFilter from '@/components/venue/VenueFilter.vue';
import { CustomerGroup } from '@/enums/CustomerGroup';
import VenueApiService from '@/api/http/VenueApiService';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';
import ExternalOrderProviderStatus from './ExternalOrderProviderStatus.vue';
const api = new VenueApiService();

@Component({
  components: { VenueFilter, VColumnSelect, VTable, ExternalOrderProviderStatus },
})
export default class VenueTable extends mixins(Notification) {
  @Prop({ type: Array, required: true }) public items!: Venue[];
  @Prop({ type: Number, required: true }) public total!: number;
  public sourceHeaders = venueHeaders;
  public headers: ColumnHeader[] = [];
  public search: string = '';

  public canSync(item: Venue) {
    if (
      item.customerGroup &&
      item.customerGroup !== CustomerGroup.Subway &&
      item.customerGroup !== CustomerGroup.SubwayFr &&
      item.customerGroup !== CustomerGroup.SubwayLu &&
      item.customerGroup !== CustomerGroup.SubwayTerminal
    ) {
      return false;
    }
    if (!item.sync) {
      return true;
    }
    return item.sync.canSync;
  }

  public async liftSyncLock(item: Venue) {
    await api.liftSyncLock(item._id);
  }

  public edit(item: Venue) {
    if (this.$can(Permission.VENUE_UPDATE)) {
      this.$router.push({ name: 'venue.edit', params: { id: item._id }, query: { ...this.$route.query } });
    } else {
      this.$router.push({ name: 'venue.editSimple', params: { id: item._id }, query: { ...this.$route.query } });
    }
  }

  public verify(item: Venue) {
    this.$router.push({ name: 'venue.verify', params: { id: item._id }, query: { ...this.$route.query } });
  }

  public publish(item: Venue) {
    this.$router.push({ name: 'venue.publish', params: { id: item._id }, query: { ...this.$route.query } });
  }

  public async copyVenue(item: Venue) {
    this.$router.push({ name: 'venue.copy', params: { id: item._id }, query: { ...this.$route.query } });
  }

  public canToggleActivation(val: boolean) {
    if (val) {
      return this.$can(Permission.VENUE_DEACTIVATE);
    }
    return this.$can(Permission.VENUE_ACTIVATE);
  }

  public isActiveExternalOrderProvider(venue: Venue) {
    if (!venue?.externalOrderProviders) return;
    const { uberEats, wolt } = venue?.externalOrderProviders;
    return uberEats?.enabled || wolt?.enabled;
  }
}
